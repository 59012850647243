import React from 'react';
import Layout from '../components/layout';
import LeadCaptureForm from '../components/LeadCaptureForm/leadCaptureForm';
import Seo from '../components/Seo';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const LeadFormUser = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/novo-usuario', label: 'Criar Minha Conta' }
  ];

  return (
    <Layout>
      <Seo
        title="Crie sua conta grátis - Prontuário Fácil"
        description="Comece a usar o Prontuário Fácil agora mesmo! Software completo para gestão de clínicas e consultórios com prontuário eletrônico, agendamento online e muito mais."
        keywords="prontuário fácil, software médico, prontuário eletrônico, gestão de clínicas, conta grátis, cadastro, criar conta"
      />
      <Breadcrumbs crumbs={crumbs} />
      <LeadCaptureForm />
    </Layout>
  );
};

export default LeadFormUser;
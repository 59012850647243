import React, { useState } from 'react';
import './leadCaptureForm.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import InputMask from 'react-input-mask';

const LeadCaptureForm = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sanityConfig = {
    projectId: '6v4gz3ce',
    dataset: 'production',
    token: 'skkA7jZCKAms5ujN3q6M3kvZbHonBC8CHZ45cNGNeRQb4QL1AXOW9d8yjm97WE1Bnu8r32bOgCMjCRBlAqYFl6y9tfnTwKwoA8pfuWjkGNexSeSnF2wNXRgqSI6jzzvDVZ6rxw080IYr4XSS5XiolsLehSUIeT929W6rOnbT0KHUuht0lBUu',
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const response = await axios.post(
        `https://${sanityConfig.projectId}.api.sanity.io/v2021-06-07/data/mutate/${sanityConfig.dataset}`,
        {
          mutations: [
            {
              create: {
                _type: 'lead',
                name: data.name,
                email: data.email,
                phone: data.phone,
                contactByWhatsApp: data.contactByWhatsApp,
                contactByEmail: data.contactByEmail,
              },
            },
          ],
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${sanityConfig.token}`,
          },
        }
      );

      console.log('Lead enviado com sucesso!', response.data);
      setFormSubmitted(true);
      reset();
    } catch (error) {
      console.error('Erro ao enviar lead:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="registration-form-container" aria-live="polite">
      <div className="registration-form-content">
        <h2 className="registration-form-title">
          Prontuário Fácil: <span className="title-highlight"> Organize sua clínica</span>
        </h2>
        <p className="registration-form-subtitle">
          Comece a usar agora mesmo e experimente a gestão de saúde
          <br />
          simplificada e eficiente.
        </p>
        <p className="registration-form-description">
          Software completo para clínicas e consultórios, com prontuário eletrônico,
          agendamento online, gestão financeira e muito mais.
        </p>
      </div>
      <div className="registration-form-wrapper">
        {formSubmitted ? (
          <div className="form-submitted-message">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
              aria-hidden="true"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <p>Obrigado! Entraremos em contato em breve.</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="registration-form"
            aria-describedby="form-description"
          >
            <div className="form-field">
              <label htmlFor="name" className="sr-only">Nome e Sobrenome</label>
              <input
                type="text"
                id="name"
                placeholder="Nome e Sobrenome"
                {...register('name', { required: 'O nome é obrigatório' })}
              />
              {errors.name && (
                <p className="error-message" role="alert">{errors.name.message}</p>
              )}
            </div>
            <div className="form-field">
              <label htmlFor="email" className="sr-only">Endereço de email</label>
              <input
                type="email"
                id="email"
                placeholder="Endereço de email"
                {...register('email', {
                  required: 'O email é obrigatório',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Insira um email válido',
                  },
                })}
              />
              {errors.email && (
                <p className="error-message" role="alert">{errors.email.message}</p>
              )}
            </div>
            <div className="form-field">
              <label htmlFor="phone" className="sr-only">Telefone</label>
              <InputMask
                mask="(99) 99999-9999"
                maskChar={null}
                placeholder="Telefone"
                {...register('phone', { required: 'O telefone é obrigatório' })}
              >
                {(inputProps) => <input type="tel" {...inputProps} id="phone" />}
              </InputMask>
              {errors.phone && (
                <p className="error-message" role="alert">{errors.phone.message}</p>
              )}
            </div>
            <div className="form-field-checkbox">
              <input
                type="checkbox"
                id="contactByWhatsApp"
                {...register('contactByWhatsApp')}
              />
              <label htmlFor="contactByWhatsApp">Desejo ser contatado por WhatsApp</label>
            </div>
            <div className="form-field-checkbox">
              <input
                type="checkbox"
                id="contactByEmail"
                {...register('contactByEmail')}
              />
              <label htmlFor="contactByEmail">Desejo ser contatado por Email</label>
            </div>
            <button
              type="submit"
              className="submit-button"
              disabled={submitting}
            >
              {submitting ? 'Enviando...' : 'Criar minha conta grátis'}
            </button>
          </form>

        )}
      </div>
    </div>
  );
};

export default LeadCaptureForm;
